<template>
    <div class="content-wrapper audit-log-container">
        <RqPageSection
            title="Filters"
            header-size="lg"
            v-model:expanded="filtersExpanded"
            collapsible
            borderless>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav rq-filter-display">
                        <li class="nav-item">
                            <div class="filter-name">Search:</div>
                            <div class="filter-value">{{searchText || "(empty)"}}</div>
                        </li>
                        <li class="nav-item">
                            <div class="filter-name">Category:</div>
                            <div class="filter-value">{{categoryFilterDisplay}}</div>
                        </li>
                        <li class="nav-item">
                            <div class="filter-name">Field:</div>
                            <div class="filter-value">{{fieldFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasFilter || searchPending">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear_search"
                                variant="theme"
                                :disabled="!hasFilter && !searchPending"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="!searchPending"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-12 col-lg-3 form-group">
                    <label for="txt_audit_log_search">Search</label>
                    <RqSearchInputGroup
                        id="txt_audit_log_search"
                        placeholder="Search..."
                        v-model="searchText"
                    />
                </div>
                <div class="col col-12 col-lg-3 form-group">
                    <label for="tag_audit_log_category">Category</label>
                    <DxTagBox
                        :input-attr="$utils.idAttrs('tag_audit_log_category')"
                        :data-source="categoryList"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="1"
                        :show-drop-down-button="true"
                        :multiline="false"
                        v-model:value="selectedCategories"
                        apply-value-mode="useButtons"
                        no-data-text="No categories found."
                        @value-changed="onCategoryValueChanged"
                    />
                </div>
                <div class="col col-12 col-lg-3 form-group">
                    <label for="tag_audit_log_field">Field</label>
                    <DxTagBox
                        :input-attr="$utils.idAttrs('tag_audit_log_field')"
                        :data-source="fieldList"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="1"
                        :show-drop-down-button="true"
                        :multiline="false"
                        display-expr="name"
                        value-expr="name"
                        apply-value-mode="useButtons"
                        no-data-text="No fields found."
                        :disabled="!hasSelectedCategories"
                        item-template="item-template"
                        v-model:value="selectedFields">
                        <template #item-template="{ data }">
                            <div>
                                <div class="d-flex justify-content-between">
                                    <div>{{data.name}}</div>
                                    <div class="font-xs text-muted fst-italic fw-light">{{data.tableName}}</div>
                                </div>
                            </div>
                        </template>
                    </DxTagBox>
                </div>
            </div>
        </RqPageSection>
        <rqdx-action-data-grid
            v-if="lookupsReady"
            ref="dataGrid"
            automation_id="dg_audit_logs"
            :data-source="gridDataSource"
            :config="gridConfig"
            :actions="selectionActions"
            hide-search
            rq-filters
        />
    </div>
</template>

<script setup>
    import { ref, computed, watchEffect } from "vue";
    import { useRqBusy, useRqToast } from "@/shared/plugins/composables";
    import { useGridInvokerMethods } from "@/shared/composables";
    import { UsersApi, AuditLogApi } from "@/api/";
    import { AuditLogQuery } from "../models";
    import {
        buildExternalFilterExpr,
        useAuditValueCellTemplate
    } from "../helpers";

    const props = defineProps({
        orderId: { type: Number, default: 0 }
    });

    const selectionActions = ref([
        { name: "version", text: "TBD", requireSelection: true, disabled: true }
    ]);

    const tableFieldLookups = ref({});
    const searchText = ref("");
    const selectedCategories = ref([]);
    const selectedFields = ref([]);
    const filtersExpanded = ref(true);

    const pendingFilterState = ref({});
    const activeFilterState = ref({});

    const lookupsReady = ref(false);
    const users = ref([]);

    const mapFieldLookup = tableName => _.map(tableFieldLookups.value?.[tableName], name => ({ name, tableName })) || [];

    const hasSelectedCategories = computed(() => !_.isEmpty(selectedCategories.value));
    const hasSelectedFields = computed(() => !_.isEmpty(selectedFields.value));
    const hasFilter = computed(() => !_.isEmpty(searchText.value)
        || hasSelectedCategories.value
        || hasSelectedFields.value);

    const searchPending = computed(() => !_.isEqual(pendingFilterState.value, activeFilterState.value));

    const categoryList = computed(() => _.keys(tableFieldLookups.value));
    const fieldList = computed(() => hasSelectedCategories.value ? _.flatMap(selectedCategories.value, mapFieldLookup) : []);

    const getFilterDisplay = items => _.isEmpty(items) ? "All" : _.join(items, ", ");
    const categoryFilterDisplay = computed(() => getFilterDisplay(selectedCategories.value));
    const fieldFilterDisplay = computed(() => getFilterDisplay(selectedFields.value));

    const { waitFor } = useRqBusy();
    const { toastError } = useRqToast();

    const auditValueCellTemplate = useAuditValueCellTemplate(users);

    watchEffect(() => {
        let searchTerm = searchText.value;
        let filters = buildExternalFilterExpr({
            orderId: props.orderId,
            tables: selectedCategories.value,
            fields: selectedFields.value
        });
        pendingFilterState.value = { searchTerm, filters };
    });

    const changeTypes = [
        { id: 1, name: "Delete" },
        { id: 2, name: "Insert" },
        { id: 3, name: "Update" }
    ];
    const gridConfig = ref({
        columns: [
            {
                dataField: "modifiedDate",
                caption: "Change Date",
                dataType: "datetime",
                width: 150,
                minWidth: 150
            },
            {
                dataField: "modifiedBy",
                caption: "Username",
                dataType: "number",
                width: 200,
                minWidth: 200,
                lookup: {
                    displayExpr: "name",
                    valueExpr: "id",
                    dataSource: {
                        load: () => users.value,
                        loadMode: "raw"
                    }
                }
            },
            {
                dataField: "changeType",
                caption: "Type",
                dataType: "number",
                width: 100,
                minWidth: 100,
                lookup: {
                    displayExpr: "name",
                    valueExpr: "id",
                    dataSource: changeTypes
                },
                // dataType: "string",
                // calculateDisplayValue: rowData => _.isString(rowData?.changeType)
                //     ? _.upperFirst(rowData.changeType)
                //     : changeTypes[(rowData.changeType || 0)],
                // rqFilter: {
                //     filterType: "tags",
                //     displayExpr: "text",
                //     valueExpr: "value",
                //     dataSource: {
                //         load: () => _.map(changeTypes, t => ({ text: t, value: _.toLower(t) })),
                //         loadMode: "raw"
                //     }
                // }
            },
            {
                dataField: "tableName",
                dataType: "string",
                caption: "Category",
                minWidth: 200,
                rqFilter: {
                    disabled: true,
                    disabledTooltip: "Use fields at the top of the page to filter this column."
                }
            },
            {
                dataField: "fieldName",
                dataType: "string",
                minWidth: 200,
                rqFilter: {
                    disabled: true,
                    disabledTooltip: "Use fields at the top of the page to filter this column."
                }
            },
            {
                dataField: "oldValue",
                dataType: "string",
                allowFiltering: false,
                allowSorting: false,
                cellTemplate: auditValueCellTemplate
            },
            {
                dataField: "newValue",
                dataType: "string",
                allowFiltering: false,
                allowSorting: false,
                cellTemplate: auditValueCellTemplate
            }
        ],
        wordWrapEnabled: false,
        remoteOperations: true
    });

    const gridDataSource = {
        key: "entryId",
        load: fetchData
    };

    const {
        dataGrid,
        invokeGridMethod,
        invokeGridComponentMethod
    } = useGridInvokerMethods();

    async function fetchLookups() {
        let apiPromises = [
            UsersApi.getUsers(),
            AuditLogApi.getTableFieldLookups()
        ];
        let results = await waitFor(Promise.all(apiPromises));
        users.value = _.map(results[0], u => ({ id: u.usersID, name: u.fullName }));
        tableFieldLookups.value = results[1];
        lookupsReady.value = true;
    }

    async function fetchData(loadOptions) {
        let externalFilters = pendingFilterState.value.filters;
        let searchQuery = AuditLogQuery.fromLoadOptions(loadOptions);
        searchQuery.searchTerm = pendingFilterState.value.searchTerm;
        searchQuery.filter = _.isEmpty(searchQuery.filter) || searchQuery.filter[0] === "entryId"
            ? externalFilters
            : [externalFilters, "and", searchQuery.filter];

        try {
            let searchPromise = AuditLogApi.search(searchQuery);
            let result = await waitFor(searchPromise);
            activeFilterState.value = _.cloneDeep(pendingFilterState.value);
            return {
                data: result?.data || [],
                totalCount: result?.totalCount || 0
            };
        }
        catch(err) {
            toastError("An issue occurred while retrieving the requested audit log entries.");
            console.error(err);
        }
    }

    const refresh = () => invokeGridMethod("refresh");

    function onSearch() {
        refresh();
    }

    function onCategoryValueChanged({ value=null }) {
        if(!_.isEmpty(value)) return;
        selectedFields.value = [];
    }

    function onClearSearch() {
        searchText.value = "";
        selectedCategories.value = [];
        selectedFields.value = [];
        let hasGridFilter = invokeGridComponentMethod("hasFilter");
        if(hasGridFilter)
            invokeGridComponentMethod("clearFilters");
        else
            refresh();
    }

    fetchLookups();
</script>

<style lang="scss">
    .audit-log-container {
        .rq-grid-action-link { cursor: pointer; }
    }
</style>