import { unref } from "vue";
import { useStore } from "vuex";
import LookupNames from "@/store/lookupNames";
import { useRqDialog } from "@/shared/plugins/composables";
import { actionLinkCellTemplate } from "@/shared/utilities/DxGridUtils";

import { AssignedLanguageListType, AppliesToType } from "../file/title-policy/enums";
import { TextControlListType } from "@/shared/models/enums";

import RtfDiff from "./components/RtfDiff.vue";

const rtfFields = {
    commitmentpolicyheader: ["ownerinsured","interestinland","currentholders","exceptions"],
    commitmentpolicydetail: ["mortgageinsured","mortgageborrower","constructionborrower","constructioninsured","liendescription","titlevestedin","legaldescription"],
    assignedlanguage: ["rtftext"]
};
const genders = [
    { id: 0, name: "Male" },
    { id: 1, name: "Female" }
];
const tinTypes = [
    { id: 0, name: "Social Security Number" },
    { id: 1, name: "Tax ID" },
    { id: 2, name: "Foreign ID" }
];
const buyerSellerTypes = [
    { id: 0, display: "Buyer" },
    { id: 1, display: "Seller" }
];
const lookupMatrix = {
    "commitmentpolicyheader": {
        "countyid": LookupNames.COUNTIES,
        "propertytypeid": LookupNames.PROPERTY_TYPES
    },
    "assignedlanguage": {
        // "standardlanguageid": "standardLanguages",
        "listtype": AssignedLanguageListType.lookupItems,
        "appliestotype": AppliesToType.lookupItems,
        "textcontrollisttype": TextControlListType.lookupItems,
        "standardlanguagesectionid": LookupNames.STANDARD_LANGUAGE_SECTIONS
    },
    "buyerseller": {
        "buyersellertype": buyerSellerTypes,
        "regionid": LookupNames.REGIONS,
        "gender1": genders,
        "gender2": genders,
        "name2name1relation": LookupNames.BUYER_SELLER_RELATIONSHIP_TYPES,
        "tintype1": tinTypes,
        "tintype2": tinTypes
    },
    "property": {
        "countyid": LookupNames.COUNTIES,
        "surveyid": LookupNames.SURVEYS,
        "propertylayoutid": LookupNames.PROPERTY_LAYOUTS,
        "subdivisionid": LookupNames.SUBDIVISIONS
    },
    "company": {
        "roletypeid": LookupNames.ROLES,
        "countyid": LookupNames.COUNTIES,
        "regionid": LookupNames.REGIONS
    }
};

const isRtfField = rowData => _.includes(rtfFields[_.toLower(rowData.tableName)], _.toLower(rowData.fieldName));

export const buildGroupFilterExpr = (filters, opr="and") => {
    let result = [];
    _.forEach(filters, f => {
        if(_.isEmpty(f)) return;
        if(!_.isEmpty(result)) result.push(opr);
        result.push(f);
    });
    return result.length === 1 ? result[0] : result;
};

export const buildListFilterExpr = (list, dataField) => {
    let filters = _.map(list, val => [dataField, "=", val]);
    return buildGroupFilterExpr(filters, "or");
};

export const buildOrderIdFilter = (id=0) => {
    let orderId = _.parseNumber(id, 0);
    if(orderId === 0) return [];
    return [
        ["ordersID", "=", orderId],
        "or", [
            ["tableName", "=", "orders"],
            "and",
            ["primaryKey", "=", orderId]
        ]
    ];
};

export const buildExternalFilterExpr = ({ orderId, tables, fields, }) => {
    let orderFilter = buildOrderIdFilter(orderId);

    if(_.isEmpty(tables) && _.isEmpty(fields)) return orderFilter;

    let tableNameFilter = buildListFilterExpr(_.map(tables, _.toLower), "tableName");
    let fieldNameFilter = buildListFilterExpr(fields, "fieldName");

    return buildGroupFilterExpr([
        orderFilter,
        tableNameFilter,
        fieldNameFilter
    ]);
}

export function useAuditValueCellTemplate(users=[]) {
    const store = useStore();
    const { openDialog } = useRqDialog();

    const launchDiffDialog = ({ oldValue, newValue }) =>
        openDialog({
            title: "View Difference",
            height: "60%",
            width: "60%",
            component: RtfDiff,
            props: {
                oldValue,
                newValue
            },
            closeOnly: true,
            closeTitle: "Close"
        });

    const isUserLookup = f => _.includes(["lastchangedby", "modifiedby"], f);

    const resolveLookupValue = (tableName, fieldName, value) => {
        let numValue = _.parseNumber(value, null);
        if(_.isNil(numValue)) return "";

        let lowerTable = _.toLower(tableName);
        let lowerField = _.toLower(fieldName);

        let lookupReference = isUserLookup(lowerField)
            ? unref(users)
            : lookupMatrix[lowerTable]?.[lowerField];

        if(_.isNil(lookupReference)) return value;

        if(_.isString(lookupReference)) {
            return store.getters.lookupHelpers.getLookupItemName(lookupReference, numValue);
        }

        if(_.isArray(lookupReference)) {
            let item = _.find(lookupReference, { id: numValue });
            return item?.name || "";
        }

        return value || "";
    };

    return (cellElement, cellInfo) => {
        if(isRtfField(cellInfo.data)) {
            if(cellInfo.column.dataField === "newValue") {
                cellElement.append("");
            }
            else {
                const rtfCellTemplate = actionLinkCellTemplate({
                    linkText: "view difference",
                    onClick: (cellElement, cellInfo) => launchDiffDialog(cellInfo?.data)
                });
                rtfCellTemplate(cellElement, cellInfo);
            }
            return;
        }
        let displayValue = resolveLookupValue(cellInfo.data?.tableName, cellInfo.data?.fieldName, cellInfo.value);
        cellElement.append(displayValue);
    };
}