export class AuditLogQuery {
    constructor (options={}) {
        options = options || {};
        this.filter = options.filter || [];
        this.searchTerm = options.searchTerm || "";
        this.sort = options.sort || options.sortBy || [];
        this.skip = options.skip || 0;
        this.take = options.take || 50;
    }

    get hasFilters() { return !_.isEmpty(this.filter); }

    static fromLoadOptions(loadOptions) {
        let result = new AuditLogQuery();
        result.parseLoadOptions(loadOptions);
        return result;
    }

    parseLoadOptions(loadOptions) {
        if (!loadOptions) return;
        if(_.isEmpty(this.filter))
            this.filter = loadOptions.filter || [];
        if(_.isEmpty(this.searchTerm))
            this.searchTerm = loadOptions.searchValue || "";
        this.skip = _.parseNumber(loadOptions.skip, 0);
        this.take = _.parseNumber(loadOptions.take, 50);
        if(_.isEmpty(loadOptions.sort)) return;
        _.each(loadOptions.sort, opt => {
            if(_.toLower(opt.selector) === "entryid") return;
            this.sort.push({
                field: opt.selector,
                direction: opt.desc ? 1 : 0
            });
        });
    }
}